import React, { useContext, useEffect, useReducer, useState } from "react";
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Home as HomeIcon, Info as InfoIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { Popover, List, ListItem, ListItemIcon, ListItemText, Badge, Collapse } from '@material-ui/core';

// herdado
import { Link as RouterLink, useHistory } from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import CalendarToday from "@material-ui/icons/CalendarToday";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
// import DarkMode from "../components/DarkMode";
import TvRoundedIcon from '@material-ui/icons/TvRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import AddIcCallRoundedIcon from '@material-ui/icons/AddIcCallRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import LoyaltyRoundedIcon from '@material-ui/icons/LoyaltyRounded';
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RotateRight from "@material-ui/icons/RotateRight";
// import { i18n } from "../translate/i18n";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { socketConnection } from "../../services/socket";
import { isArray } from "lodash";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ListSubheader: {
        height: 26,
        marginTop: "-15px",
        marginBottom: "-10px",
    },
}));


function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <ListItem button dense component={renderLink}>
            <MenuItem >
                <IconWrapper>{icon}</IconWrapper>
                {primary}
            </MenuItem>
        </ListItem>
    );
}

function ListItemLinkSub(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );



    return (
        <ListItem button dense component={renderLink}>
            <ListItemIcon style={{"color": "rgb(129, 115, 255)"}}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={primary} />
        </ListItem>

    );
}


const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};


const MenuContainer = styled.div`
//   background-color: white;
//   height: 5.2em;
//   position: relative;
display: flex;
justify-content: center;
background-color: white;
height: 4.4em;
position: fixed;
width: 100%;
z-index: 988;
top: 60px;
}
`;

const MenuList = styled.ul`
  display: flex;
  place-content: start;
  flex-wrap: nowrap;
  align-content: flex-end;
  padding-left: 0px;
  position: absolute;
  bottom: 0;
`;
const SubMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #ffffff;
  padding: 0px;
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
  width: 19.5em;
`;


const MenuItem = styled.li`
  color: #8173FF; 
  font-size: 15px; 
  cursor: pointer;
  margin-right: 20px; 
  font-family: 'Istok Web', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 0px;
  &:hover {
    & > .submenu {
      display: block;
    }
  }
`;



const MenuBorder = styled.div`
  padding: 5px;
  border-bottom: 6px solid transparent; /* Adiciona um border transparente para criar o efeito de transição */
  transition: border-bottom-color 0.7s; /* Adiciona uma transição suave para a cor do border-bottom */
  &:hover {
    border-bottom-color: #8173FF; /* Altera a cor do border-bottom ao passar o mouse */
  }
`;
const IconWrapper = styled.div`
  margin-right: 5px;
`;
// listItems: {
//     dashboard: "Dashboard",
//     connections: "Conexões",
//     tickets: "Atendimentos",
//     quickMessages: "Respostas Rápidas",
//     contacts: "Contatos",
//     queues: "Filas & Perguntas",
//     tags: "Tags",
//     administration: "Administração",
//     service: "Atendimento",
//     users: "Usuários",
//     settings: "Configurações",
//     helps: "Ajuda",
//     messagesAPI: "API",
//     schedules: "Agendamentos",
//     campaigns: "Campanhas",
//     annoucements: "Informativos",
//     chats: "Chat Interno",
//     financeiro: "Financeiro",
//     logout: "Sair",
//     management: "Gerência",
//     kanban: "Kanban"
//   },
function Header(props) {


    const classes = useStyles();
    const { drawerClose, drawerOpen } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user, handleLogout } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
    const [openKanbanSubmenu, setOpenKanbanSubmenu] = useState(false);

    const [showCampaigns, setShowCampaigns] = useState(false);
    const history = useHistory();
    const [invisible, setInvisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState("");
    const [chats, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === "new-message") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    useEffect(() => {
        if (localStorage.getItem("cshow")) {
            setShowCampaigns(true);
        }
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_CHATS", payload: data.records });
        } catch (err) {
            toastError(err);
        }
    };

    const handleClickLogout = () => {
        //handleCloseMenu();
        handleLogout();
    };



    return (
        <>
            <MenuContainer>
                <MenuList>

                    {/*  I CAN */}
                    <Can
                        role={user.profile}
                        perform={"drawer-admin-items:view"}
                        yes={() => (
                            <>

                                <MenuBorder>

                                    <ListItemLink
                                        to="/"
                                        primary={"Dashboard"}
                                        icon={<AppsRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                            </>
                        )}
                    />
                    {/* END I CAN */}
                    <Can
                        role={user.profile}
                        perform={"drawer-service-items:view"}
                        style={{
                            overflowY: "scroll",
                        }}
                        no={() => (
                            <>
                                <MenuBorder>
                                    <ListItemLink
                                        to="/tickets"
                                        primary={"Atendimento"}
                                        icon={<AddIcCallRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                                <MenuBorder>
                                    <MenuItem>
                                        <ListItemLink
                                            to="/kanban"
                                            primary={"Funil"}
                                            icon={<CalendarToday fontSize="medium" />}
                                        />
                                    </MenuItem>
                                </MenuBorder>
                                <MenuBorder>
                                    <ListItemLink
                                        to="/contacts"
                                        primary={"Clientes"}
                                        icon={<SupervisorAccountRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                            


                            </>
                        )}
                    />
                    <Can
                        role={user.profile}
                        perform={"drawer-admin-items:view"}
                        style={{
                            overflowY: "scroll",
                        }}
                        yes={() => (
                            <>
        
                                <MenuBorder>
                                    <ListItemLink
                                        to="/contacts"
                                        primary={"Clientes"}
                                        icon={<SupervisorAccountRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                                <MenuBorder>
                                    <ListItemLink
                                        to="/queues"
                                        primary={"Filas & Bot"}
                                        icon={<AccountTreeRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                                <MenuBorder>
                                    <ListItemLink
                                        to="/schedules"
                                        primary={"Agenda"}
                                        icon={<EventIcon fontSize="medium" />}
                                    />
                                </MenuBorder>
                                <MenuBorder>
                                    <ListItemLink
                                        to="/connections"
                                        primary={"Canais"}
                                        icon={<TvRoundedIcon fontSize="medium" />}
                                    />
                                </MenuBorder>


                            </>
                        )}
                    />


                    <MenuItem>
                        <IconWrapper><AccountCircleIcon fontSize="medium" /></IconWrapper>
                        Administração
                        <SubMenu
                            className="submenu"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <List>

                                {/*  I AM CAN  IF FOR ADMIN */}
                                <Can
                                    role={user.profile}
                                    perform="drawer-admin-items:view"
                                    yes={() => (
                                        <>
                                            <ListItemLinkSub
                                                to="/users"
                                                primary={"Usuário"}
                                                icon={<PeopleAltOutlinedIcon fontSize="medium" />}
                                            />
                                            <ListItemLinkSub
                                                to="/financeiro"
                                                primary={"Financeiro"}
                                                icon={<LocalAtmIcon fontSize="medium" />}
                                            />
                                            <ListItemLinkSub
                                                to="/settings"
                                                primary={"Configuracao"}
                                                icon={<SettingsOutlinedIcon fontSize="medium" />}
                                            />
                                            <ListItemLinkSub
                                                to="/chats"
                                                primary={"Chat Interno"}
                                                icon={<ForumIcon fontSize="medium" />}
                                            />
                                            <ListItemLinkSub
                                                to="/campaigns"
                                                primary={"Chat Interno"}
                                                icon={<SettingsOutlinedIcon fontSize="medium" />}
                                            />




                                            {showCampaigns && (
                                                <>

                                                </>
                                            )}
                                            {user.super && (

                                                <ListItemLinkSub
                                                    to="/announcements"
                                                    primary={"Chat Interno"}
                                                    icon={<AnnouncementIcon fontSize="medium" />} />


                                            )}

                                            { }

                                        </>
                                    )}
                                />
                                {/* END CAN I AM  */}



                            </List>
                        </SubMenu>
                    </MenuItem>



                </MenuList>
            </MenuContainer>
        </>
    );
}

export default Header;
