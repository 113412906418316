import React, { useState, useContext } from "react";import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SendIcon from "@material-ui/icons/Send";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logologin.png";


// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		height: "100vh",
		background: "linear-gradient(to right, #1ad3b8, #3ad9c2, #1ad3b8)", //Cor de fundo
		// backgroundImage: "url(https://helloclique.com.br/imagens/logo1.png)",
		 backgroundRepeat: "no-repeat",
		 backgroundSize: "100% 100%",
		 backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	paper: {
		backgroundColor: "#fff",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "55px 30px",
		borderRadius: "12.5px",
	},
	avatar: {
		margin: theme.spacing(1),  
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	powered: {
		color: "black"
	}
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};
	

	  
	return (
		<div className={classes.root}>
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<div>
					{ <img style={{ margin: "0 auto", width: "80%", radius: "10px" }} src={logo} alt="ivobot" /> }
				</div>
				<Typography component="h1" variant="h5">
					{/* {i18n.t("login.title")} */}
				</Typography>
				<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						type="password"
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
					/>
					{/* <Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>


						{i18n.t("login.buttons.submit")}
					</Button> */}

					<Button  type="submit" 
					         variant="contained"
							 color="primary"

							 fullWidth
							 className={classes.submit}
							 endIcon={<SendIcon />}>
												{i18n.t("login.buttons.submit")}

						
					</Button>
					<Grid container>
						<Grid item>
							<Link
							    style={{"text-align": "center", 
								"margin": "48px",
								"font-weight": "bolder"}}
								href="#"
								variant="body2"
								component={RouterLink}
								to="/signup"
							>
								{i18n.t("login.buttons.register")}
							</Link>
						</Grid>
					</Grid>
				</form>
			</div>
			<br/>
			{/* <a target="_blank" href="https://ivoxbot.com.br" className={classes.powered}>demo - www.ivoxbot.com.br</a> */}
			<Box mt={8}>{/* <Copyright /> */}</Box>
		</Container>
		</div>
	);
};

export default Login;

